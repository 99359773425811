require("./bootstrap");

require("jquery/dist/jquery.min.js");
// <!-- Bootstrap JS bundle - Bootstrap + PopperJS-->
require("bootstrap/dist/js/bootstrap.bundle.min.js");
// // <!-- Magnific Popup - Lightbox for the gallery-->
require("magnific-popup/dist/jquery.magnific-popup.min.js");
// // <!-- Smooth scroll-->
window.SmoothScroll = require("smooth-scroll/dist/smooth-scroll.polyfills.min.js");
// // <!-- Bootstrap Select-->
require("bootstrap-select/dist/js/bootstrap-select.min.js");

window.Swiper = require("./themes/directory-2-0/swiper.min.js");

require("moment/dist/moment.js");

window.daterangepicker = require("./themes/directory-2-0/daterangepicker.min.js");
